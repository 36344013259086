import * as React from 'react'
import { useCallback, useState } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { Alert, InputAdornment, Grid2 as Grid } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import TextField from '@mui/material/TextField'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import debounce from 'lodash.debounce'
import type { PropertyTemplate, PropertyTemplatesQuery } from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import AuthorsCell from 'src/components/AuthorsCell'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button'
import Divider from 'src/components/Library/Divider/Divider'
import { default as EmptyData } from 'src/components/Library/Empty'
import IconButton from 'src/components/Library/IconButton'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import SavingText from 'src/components/Library/SavingText/SavingText'
import { fieldTypes } from 'src/components/PropertyGenie/helper'
import PropertyTemplateForm from 'src/components/PropertyGenie/PropertyTemplateForm'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import {
  CREATE_PROPERTY_TEMPLATE,
  DELETE_PROPERTY_TEMPLATE,
  UPDATE_PROPERTY_TEMPLATE,
} from 'src/lib/queries/PropertyGenie/PropertyTemplate'
import { useAuth } from 'src/Providers'

export const QUERY = gql`
  query PropertyTemplatesQuery {
    propertyTemplates {
      id
      name
      content
      footer
      createdBy
      createdAt
      updatedAt
      updatedBy
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  const loading = useBoolean(false)
  const drawOpen = useBoolean(false)
  const { currentUser } = useAuth()
  const clientId = currentUser?.parentData?.id
  const [createPropertyTemplate] = useMutation(CREATE_PROPERTY_TEMPLATE, {
    onCompleted: () => {
      toast.success('Template Created')
      loading.toggle()
    },
    refetchQueries: ['PropertyTemplatesQuery'],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message)
      loading.toggle()
    },
  })

  return (
    <div className="my-4 ml-6 flex h-full w-1/2">
      <div className="grid grow place-items-center">
        <EmptyData title="No Templates Found" className={'mt-4'} />
      </div>
      <Drawer
        elevation={0}
        variant="permanent"
        sx={{
          flexShrink: 0,
          zIndex: 50,
          '& .MuiDrawer-paper': {
            height: 'calc(100vh - 64px)',
            top: '64px',
            width: '50%',
            zIndex: 50,
            boxSizing: 'border-box',
            borderLeft: '1px solid #e0e0e0',
            position: 'absolute',
          },
        }}
        anchor="right"
        open={drawOpen.value}
      >
        <PropertyTemplateForm
          onSave={(input) => {
            loading.toggle()
            createPropertyTemplate({
              variables: { input: { ...input, clientId } },
            })
          }}
        />
      </Drawer>
    </div>
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  propertyTemplates,
}: CellSuccessProps<PropertyTemplatesQuery>) => {
  enum activeViews {
    NEW_TEMPLATE,
    EDIT_TEMPLATE,
    NONE,
  }
  const { currentUser, hasRole } = useAuth()
  const clientId = currentUser?.parentData?.id
  const isAdminAccess = hasRole(['ADMIN', 'OWNER', 'EDITOR', 'SUPERADMIN'])

  const { trackEvent } = useAnalytics()

  const [selectedTemplate, setSelectedTemplate] =
    useState<PropertyTemplate | null>(null)
  const [activeView, setActiveView] = useState<activeViews>(activeViews.NONE)
  const drawOpen = useBoolean(false)
  const [templateContent, setTemplateContent] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [templateFooter, setTemplateFooter] = useState('')
  const loading = useBoolean(false)
  const confirm = useConfirm()
  const visibleFields = ['name', 'author']
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'author',
      headerName: 'Author',
      flex: 1,
      renderCell: (params) => {
        return (
          <AuthorsCell
            createdById={params.row.createdBy}
            updatedById={params.row.updatedBy}
            createdAt={params.row.createdAt}
            updatedAt={params.row.updatedAt}
          />
        )
      },
    },
  ]

  const [updatePropertyTemplate] = useMutation(UPDATE_PROPERTY_TEMPLATE, {
    onCompleted: () => {
      toast.success('Template Updated')
      loading.toggle()
    },
    refetchQueries: ['PropertyTemplatesQuery'],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message)
      loading.toggle()
    },
  })

  const [deletePropertyTemplate] = useMutation(DELETE_PROPERTY_TEMPLATE, {
    onCompleted: () => {
      toast.success('Template Deleted')
      loading.toggle()
      setSelectedTemplate(null)
      setActiveView(activeViews.NONE)
    },
    refetchQueries: ['PropertyTemplatesQuery'],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message)
      loading.toggle()
    },
  })

  const [createPropertyTemplate] = useMutation(CREATE_PROPERTY_TEMPLATE, {
    onCompleted: () => {
      toast.success('Template Created')
      loading.toggle()
      setActiveView(activeViews.NONE)
    },
    refetchQueries: ['PropertyTemplatesQuery'],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message)
      loading.toggle()
    },
  })

  const saveTemplate = () => {
    if (
      selectedTemplate.content !== templateContent ||
      selectedTemplate.footer !== templateFooter ||
      selectedTemplate.name !== templateName
    ) {
      loading.toggle()
      updatePropertyTemplate({
        variables: {
          id: selectedTemplate?.id,
          input: {
            name: templateName,
            content: templateContent,
            footer: templateFooter,
          },
        },
      })
    }
  }
  const debouncedSave = useCallback(debounce(saveTemplate, 1000, true), [
    selectedTemplate,
    templateContent,
    templateFooter,
    templateName,
  ])
  const handleSearch = (template: PropertyTemplate | null | undefined) => {
    if (template) {
      setSelectedTemplate(template)
      setTemplateContent(template.content)
      setTemplateFooter(template.footer)
      setTemplateName(template.name)
      setActiveView(activeViews.EDIT_TEMPLATE)
      drawOpen.setTrue()
    }
  }

  return (
    <div className={'flex h-[calc(100%-50px)] w-full flex-row'}>
      <div className="mx-3 h-full w-1/2">
        <Grid container spacing={2} className="my-2">
          <Grid size={{ xs: 6 }}>
            <Autocomplete<PropertyTemplate>
              fullWidth
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Search" />}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )
              }}
              options={propertyTemplates}
              onChange={(_, newValue) => {
                handleSearch(newValue)
                trackEvent('Ad Generator', 'search templates', {
                  templateName: newValue?.name,
                })
              }}
            />
          </Grid>
          <Grid size={{ xs: 6 }}>
            <div className={'flex h-full items-center justify-end'}>
              <Button
                variant="contained"
                onClick={() => {
                  setActiveView(activeViews.NEW_TEMPLATE)
                  trackEvent('Ad Generator', 'click add template')
                }}
                fullWidth={false}
              >
                Add Template
              </Button>
            </div>
          </Grid>
        </Grid>
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          autoHeight
          loading={loading.value}
          rows={propertyTemplates}
          columns={columns}
          onRowClick={(params) => {
            setSelectedTemplate(params.row)
            setTemplateContent(params.row.content)
            setTemplateFooter(params.row.footer)
            setTemplateName(params.row.name)
            setActiveView(activeViews.EDIT_TEMPLATE)
            drawOpen.setTrue()
          }}
        />
      </div>
      <div
        className={
          'h-full w-full overflow-y-auto border-l-[1px] border-gray-200 bg-white'
        }
      >
        {
          {
            [activeViews.EDIT_TEMPLATE]: (
              <div className={'w-full p-8'}>
                <div className={'flex w-full flex-row justify-end'}>
                  {isAdminAccess && (
                    <IconButton
                      className={'mr-4'}
                      onClick={() => {
                        confirm({
                          title: 'Are you sure?',
                          description:
                            'This action cannot be undone, and the property data will be lost.',
                          confirmationText: 'Delete',
                        })
                          .then(async (isConfirmed) => {
                            if (!isConfirmed) return
                            await deletePropertyTemplate({
                              variables: {
                                id: selectedTemplate.id,
                              },
                            })
                          })
                          .catch(() => {})
                      }}
                      color={'error'}
                    >
                      <TrashIcon className={'h-5 w-5'} />
                    </IconButton>
                  )}
                  <SavingText saving={loading.value} />
                </div>
                <Divider className={'my-4'} label={'Template Name'} chip />
                <TextField
                  required
                  onChange={(e) => {
                    setTemplateName(e.target.value)
                  }}
                  onBlur={() => {
                    debouncedSave()
                  }}
                  value={templateName}
                  fullWidth
                />
                <Divider className={'my-4'} label={'Content'} chip />
                <Alert className={'mt-1'} severity="info">
                  {
                    'Tags can be added manually using the format {tag}, or by clicking the + button.'
                  }
                </Alert>
                <Autocomplete
                  fullWidth
                  className={'my-4'}
                  onBlur={() => {
                    debouncedSave()
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Predefined Tags"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position={'end'}>
                            {params.InputProps.endAdornment}
                            {/*  Pluss button */}
                            <IconButton
                              className={'h-10 w-10'}
                              onClick={() => {
                                setTemplateContent(
                                  templateContent + params.inputProps.value,
                                )
                              }}
                            >
                              +
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  options={fieldTypes}
                />
                <TextareaAutosize
                  onChange={(e) => {
                    setTemplateContent(e.target.value)
                  }}
                  onBlur={() => {
                    debouncedSave()
                  }}
                  value={templateContent}
                  className={'w-full rounded border-2 border-gray-300 p-2'}
                />
                <Divider className={'my-4'} label={'Footer'} chip />
                <TextareaAutosize
                  onChange={(e) => {
                    setTemplateFooter(e.target.value)
                  }}
                  onBlur={() => {
                    debouncedSave()
                  }}
                  value={templateFooter}
                  className={'w-full rounded border-2 border-gray-300 p-2'}
                />
                <Alert className={'mt-1'} severity="info">
                  Use the footer to include standard details. For example, a
                  call to action or any disclaimers
                </Alert>
              </div>
            ),
            [activeViews.NEW_TEMPLATE]: (
              <PropertyTemplateForm
                onSave={(input) => {
                  loading.toggle()
                  createPropertyTemplate({
                    variables: { input: { ...input, clientId } },
                  })
                }}
              />
            ),
            [activeViews.NONE]: (
              <div className={'flex h-full items-center'}>
                <EmptyData
                  title="No Template Selected"
                  description="Select a template to edit"
                />
              </div>
            ),
          }[activeView]
        }
      </div>
    </div>
  )
}
